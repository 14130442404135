<template>
  <base-modal :show="show" full @close="closeModal">
    <div class="modal-container">
      <h3 class="modal-title bb-description-6 bb-letter-spacing-1">
        {{ translate('Descargá') }} tu QR para mostrarle a tus clientes qué
        incluyen tus experiencias.
      </h3>
      <ul class="modal-list bb-description-3">
        <li class="list-line">
          <ListNumber01Icon class="list-icon" aria-label="01" />
          <p>{{ translate('Imprimí') }} tu código QR.</p>
        </li>
        <li class="list-line">
          <ListNumber02Icon class="list-icon" aria-label="02" />
          <p>{{ translate('Mostralo') }} en tu establecimiento.</p>
        </li>
        <li class="list-line">
          <ListNumber03Icon class="list-icon" alt="03" />
          <p>
            Tus clientes podrán escanearlo y ver tus propuestas Bigbox
            disponibles.
          </p>
        </li>
      </ul>
      <!--TODO make button download image directly-->
      <button-custom
        class-name="--w-325 bb-mt-5 bb-mb-5"
        text="Descargar"
        @click="openQRCode"
      />
      <button-custom
        class-name="--w-325 --tertiary bb-underline bb-mb-2"
        text="Mirá la página a la que lleva el QR"
        @click="openQRCodeForQA"
      />
    </div>
  </base-modal>
</template>
<script setup lang="ts">
  import { computed } from 'vue';
  import translate from '@/helpers/translate';

  import BaseModal from '@/components/Common/BaseModal/BaseModal.vue';
  import ButtonCustom from '@/components/Common/ButtonCustom/ButtonCustom.vue';

  import ListNumber01Icon from '@/assets/icons/ListNumber01.svg';
  import ListNumber02Icon from '@/assets/icons/ListNumber02.svg';
  import ListNumber03Icon from '@/assets/icons/ListNumber03.svg';
  import { usePartnerInfoStore } from '@/stores/partnerInfoStore';

  import { useRouter } from 'vue-router';

  const router = useRouter();

  const partnerInfoStore = usePartnerInfoStore();
  const emit = defineEmits(['close']);

  interface Props {
    show?: boolean;
  }

  defineProps<Props>();

  const qrCodeInfo = computed(() => partnerInfoStore.profile.qr_code);

  const openQRCode = () => {
    window.open(qrCodeInfo.value?.qr_code);
  };

  const openQRCodeForQA = () => {
    router.push({
      name: 'menu-list',
      params: {
        partnerSlug: 'tucson-2886',
      },
    });
  };

  const closeModal = () => emit('close');
</script>
<style lang="scss" scoped>
  .modal-container {
    max-width: 386px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $screen-sm-max) {
      margin-top: 20px;
    }
  }

  .modal-title {
    font-weight: 600;
    text-align: left;
    margin-bottom: 54px;
  }

  .modal-list {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 64px;
  }

  .list-line {
    display: flex;
    gap: 24px;
  }

  .list-icon {
    overflow: visible;
    height: 22px;
    width: 36px;
  }
</style>
